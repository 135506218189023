







































































import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import zuersZoneModule from '@/store/modules/zuersZone';
import ZuersZone from '@/components/form/zuers-zone.vue';

export default Vue.extend({
  components: {
    ZuersZone,
  },
  props: {
    geltungsbereicheItem: {
      type: Object as () => {
        isSelected: boolean;
        num: number;
        strasse: string;
        plz: string;
        ort: string;
        land: string;
        hausnummer: string;
        zuers: number;
        starkregenzone: number;
        naturgefahren2: string;
        naturgefahren3: string;
        geltungsbereich: string;
        individualAgreements: string;
      },
      required: true,
    },
    index: Number,
  },
  data() {
    return {
      moduleNamespace: `zuersZoneInstance${this.index + 1}`,
      dataFilled: false,
    };
  },
  methods: {
    ...mapMutations([
      'SET_PLACE',
      'SET_POSTAL_CODE',
      'SET_STREET',
      'SET_HOUSE_NUMBER',
      'SET_ZURS',
      'RESET_STATE',
    ]),
    setZuersAddressToStore() {
      this.$store.commit(`${this.moduleNamespace}/RESET_STATE`);
      this.$store.commit(`${this.moduleNamespace}/SET_PLACE`, this.geltungsbereicheItem.ort);
      this.$store.commit(`${this.moduleNamespace}/SET_POSTAL_CODE`, this.geltungsbereicheItem.plz);
      this.$store.commit(`${this.moduleNamespace}/SET_STREET`, this.geltungsbereicheItem.strasse);
      this.$store.commit(`${this.moduleNamespace}/SET_HOUSE_NUMBER`, this.geltungsbereicheItem.hausnummer ?? '');
      this.$store.commit(`${this.moduleNamespace}/SET_ZURS`, this.geltungsbereicheItem.zuers);
      this.dataFilled = true;
    },
    updateStreet(value: string) {
      this.$store.commit(`${this.moduleNamespace}/SET_STREET`, value);
    },
    updatePostalCode(value: string) {
      this.$store.commit(`${this.moduleNamespace}/SET_POSTAL_CODE`, value);
    },
    updatePlace(value: string) {
      this.$store.commit(`${this.moduleNamespace}/SET_PLACE`, value);
    },
    updateHouseNumber(value: string) {
      this.$store.commit(`${this.moduleNamespace}/SET_HOUSE_NUMBER`, value);
    },
  },
  computed: {
    getZurs() {
      return this.$store.getters[`${(this as any).moduleNamespace}/getZurs`];
    },
  },
  watch: {
    getZurs(newVal: number) {
      if (newVal !== undefined) {
        this.geltungsbereicheItem.zuers = newVal;
        this.geltungsbereicheItem.starkregenzone = newVal;
      }
    },
    geltungsbereicheItem: {
      handler(newVal) {
        if (!this.dataFilled) {
          this.setZuersAddressToStore();
        }
      },
      deep: true,
    },
  },
  created() {
    const store = this.$store as any;
    // eslint-disable-next-line no-underscore-dangle
    if (!store._modulesNamespaceMap[`${this.moduleNamespace}/`]) {
      store.registerModule(this.moduleNamespace, {
        ...zuersZoneModule, namespaced: true,
      });
    }

    this.$watch(
      () => this.$store.getters[`${this.moduleNamespace}/getZurs`],
      (newVal) => {
        if (newVal !== undefined) {
          this.geltungsbereicheItem.zuers = newVal;
          this.geltungsbereicheItem.starkregenzone = newVal;
        }
      },
    );
  },
  beforeDestroy() {
    const store = this.$store as any;
    // eslint-disable-next-line no-underscore-dangle
    if (store._modulesNamespaceMap[`${this.moduleNamespace}/`]) {
      store.unregisterModule(this.moduleNamespace);
    }
  },
});
