










































































import Vue from 'vue';
import IVertragsfaten from '@/model/IVertragsfaten';

export default Vue.extend({
  name: 'vertragsdaten-mashine',
  props: {
    paramsVertragsdaten: Object,
  },
  computed: {
    factorLevelYearsOptions(): number[] {
      const currentYear = (new Date()).getFullYear();
      const values = [] as number[];

      for (let i = currentYear; i > 2020; i -= 1) {
        values.push(i);
      }

      return values;
    },
  },
  watch: {
    paramsVertragsdaten(value: IVertragsfaten) {
      this.$emit('params-vertragsdaten-change', value);
    },
  },
  data() {
    return {
      vertragsdatenOpen: true,
    };
  },
});
