import { WatchOptions } from 'vue';

export default {
  'paramsVertragsdaten.paymentMethod': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  clonedPositionsdatenItems: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        newVal.forEach((item: any, index: number) => {
          if (item.SB !== oldVal[index]?.SB) {
            this.$emit('change-data-for-price-request', true);
          }
          if (item.MKZ !== oldVal[index]?.MKZ) {
            this.$emit('change-data-for-price-request', true);
          }
          if (item.Deckungsform !== oldVal[index]?.Deckungsform) {
            this.$emit('change-data-for-price-request', true);
          }
          if (item.VandalismusMTV !== oldVal[index]?.VandalismusMTV) {
            this.$emit('change-data-for-price-request', true);
          }
          if (item.Diebstahlrisiko !== oldVal[index]?.Diebstahlrisiko) {
            this.$emit('change-data-for-price-request', true);
          }
          if (item.Bewertungsjahr !== oldVal[index]?.Bewertungsjahr) {
            this.$emit('change-data-for-price-request', true);
          }
          if (item.Versicherunswert !== oldVal[index]?.Versicherunswert) {
            this.$emit('change-data-for-price-request', true);
          }
          // if (item.VSU !== oldVal[index]?.VSU) {
          //   this.$emit('change-data-for-price-request', true);
          // }
          if (item.VermietungUndVerpachtung !== oldVal[index]?.VermietungUndVerpachtung) {
            this.$emit('change-data-for-price-request', true);
          }
          if (item.GAPDeckung !== oldVal[index]?.GAPDeckung) {
            this.$emit('change-data-for-price-request', true);
          }
          if (item.Neuheitsrabatt !== oldVal[index]?.Neuheitsrabatt) {
            this.$emit('change-data-for-price-request', true);
          }
          if (item.Spindelklausel !== oldVal[index]?.Spindelklausel) {
            this.$emit('change-data-for-price-request', true);
          }
        });
      }
    },
    deep: true,
  },
  erstrisikosummenItems: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  'pramienSelbstbehaltsvariantenItems.tarif.SB': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'tarif');
    }
  },
  'pramienSelbstbehaltsvariantenItems.variant1.SB': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant1');
    }
  },
  'pramienSelbstbehaltsvariantenItems.variant2.SB': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant2');
    }
  },
  'pramienSelbstbehaltsvariantenItems.variant3.SB': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant3');
    }
  },
} as Record<string, any>;

